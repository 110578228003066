import React, { useState, useEffect } from 'react'

import Header from 'components/header'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import useBackgroundColor from 'utils/useBackgroundColor'
import Footer from 'components/footer'
import useStore from 'state'
import 'intersection-observer'

import './reset.scss'
import './index.scss'
import './fonts.scss'
import './type.scss'

const Layout = ({
  location, data, children
}) => {
  const bgColor = useBackgroundColor(location)
  useEffect(() => {
    document.body.style.backgroundColor = bgColor
  }, [bgColor])

  const [resetFooter, setResetFooter] = useState(false)
  const [toggleFooter, setToggleFooter] = useState(false)
  useEffect(() => {
    setResetFooter(undefined)
    setToggleFooter(false)
  }, [location.pathname])

  const setY = useStore(state => state.setY)
  useEffect(() => {
    if (location.pathname === '/') return
    setY(0)
  }, [location.pathname])

  return (
    <>
      <TransitionProvider
        location={location}
        enter={{
          opacity: 0,
          onRest: () => {
            setResetFooter(true)
            setToggleFooter(undefined)
          },
          transform: 'translate3d(0, 0vh, 0)'
        }}
        usual={{
          opacity: 1,
          transform: 'translate3d(0, 0vh, 0)'
        }}
        leave={{
          opacity: 0,
          transform: 'translate3d(0, 0vh, 0)'
        }}
      >
        <Header location={location} bgColor={bgColor} />
        <TransitionViews>
          {children}
        </TransitionViews>
        <Footer
          disable={location.pathname === '/impressum-datenschutz/'}
          reset={resetFooter}
          toggle={toggleFooter}
        />
      </TransitionProvider>
    </>
  )
}

export default Layout
