import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import gridProps from 'layouts/gridProps'
import { Grid } from 'react-raster'
import { useSpring, animated } from 'react-spring'
import './index.scss'

const LinkDefault = ({ children, location, to, linkName }) => {
  const [style, setStyle] = useState()

  useEffect(() => {
    const locationArray = location.split('/').filter(el => el)
    const toArray = to.split('/').filter(el => el)

    if (to === '/' && locationArray.includes('showcase')) {
      setStyle({ color: 'black', pointerEvents: locationArray.length === 1 && 'none' })
      return
    }
    if (to === '/' && locationArray[0] === undefined) {
      setStyle({ color: 'black', pointerEvents: 'none' })
      return
    }
    if (locationArray.includes(toArray[0])) {
      setStyle({ color: 'black', pointerEvents: 'none' })
    } else {
      setStyle({})
    }
  }, [location])

  return (
    <Link
      className={`header__nav__link header__nav__link--${linkName} size--1`}
      to={to}
      style={style}
    >
      {children}
    </Link>
  )
}

const Header = ({ location, bgColor }) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })
  return (
    <animated.header
      className='header'
      style={{ ...props, background: bgColor }}
    >
      <Grid
        className='header__inner'
        {...gridProps}
        // gutterX={0}
        alignY={'top'}
        alignX={'space-between'}
        control={process.env.NODE_ENV === 'development'}
        controlColor='rgba(0,0,0,0.1)'
      >
        <nav className='header__nav'>
          <LinkDefault to='/' location={location.pathname} linkName='showcase'>
            Showcase
          </LinkDefault>
          <LinkDefault to='/archive' location={location.pathname} linkName='archive'>
            Archive
          </LinkDefault>
          <LinkDefault to='/studio' location={location.pathname} linkName='studio'>
            Studio
          </LinkDefault>
        </nav>
      </Grid>
    </animated.header>
  )
}

export default Header
