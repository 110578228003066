import { useEffect, useState } from 'react'

export default (location) => {
  const [bg, setBg] = useState()

  useEffect(() => {
    const locationArray = location.pathname.split('/').filter(el => el)
    if (locationArray.includes('studio')) {
      setBg('#ff3333')
    } else if (locationArray.includes('archive')) {
      setBg('#6633ff')
    } else if (locationArray.includes('impressum-datenschutz')) {
      setBg('#fbe947')
    } else {
      setBg('hsl(136, 100%, 70%)')
    }
  }, [location.pathname])

  return bg
}
