import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, Box } from 'react-raster'
import './index.scss'
import gridProps from 'layouts/gridProps'
import debounce from 'lodash/debounce'

const useHeights = (reset) => {
  const [bodyHeight, setBodyHeight] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)

  useEffect(() => {
    function onResize () {
      setBodyHeight(document.body.offsetHeight)
      setWindowHeight(window.innerHeight)
    }
    onResize()
    const dOnResize = debounce(onResize, 150)
    window.addEventListener('resize', dOnResize)

    return () => window.removeEventListener('resize', dOnResize)
  }, [reset])

  return { windowHeight, bodyHeight }
}

const Footer = ({ disable, toggle, reset }) => {
  const [show, setshow] = useState(false)
  const { windowHeight, bodyHeight } = useHeights(reset)

  useEffect(() => {
    if (typeof toggle === 'boolean') setshow(toggle)
  }, [toggle])

  useEffect(() => {
    function onScroll () {
      if (disable) return
      if ((windowHeight + window.pageYOffset) >= bodyHeight - 2) {
        setshow(true)
      } else {
        setshow(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [disable, bodyHeight])

  return (
    <Grid
      tag='footer'
      className='footer'
      {...gridProps}
      style={`
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity 0.4s, transform 0.4s;
        opacity: ${show ? '1' : '0'};
        transform: translate3d(0, ${show ? '0' : '100'}%, 0);
      `}
    >
      <Box
        className='impressum'
      >
        <Link
          className={`nav--impressum__link size--0`}
          to='/impressum-datenschutz'
        >
        Impressum &amp; Datenschutz
        </Link>
      </Box>
    </Grid>
  )
}

export default Footer
