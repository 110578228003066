const gridProps = {
  colspan: 12,
  gutterX: ['2vw', '1.5vw', '1vw'],
  gutterY: ['2vw', '1.5vw', '1vw'],
  top: ['2vw', '1.5vw', '1vw'],
  left: ['2vw', '1.5vw', '1vw'],
  right: ['2vw', '1.5vw', '1vw'],
  bottom: ['2vw', '1.5vw', '1vw'],
  control: true
}

export default gridProps
