// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-work-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-404-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-impressum-datenschutz-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/impressum-datenschutz.js" /* webpackChunkName: "component---src-pages-impressum-datenschutz-js" */),
  "component---src-pages-index-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-studio-js": () => import("/var/www/virtual/lisaweis/html/lisaweiss.ch/frontend/src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

